<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">{{ widgetTitle }}</h4>
                <h6 class="card-subtitle">{{ widgetSubTitle }}</h6>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="chart">
            <div class="chart__canvas">
                <canvas baseChart [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions"
                    [chartType]="chartType">
                </canvas>
            </div>
            <ul class="chart__legends">
                <li *ngFor="let item of chartData">
                    <i [ngStyle]="{ backgroundColor: item.backgroundColor }"></i>
                    {{ item.label }}
                </li>
            </ul>
        </div>

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="ano">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ano </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex-grow-1 text-headings d-flex align-items-center">
                        <i class="w-10px h-10px rounded-circle mr-3" style="background-color: {{element.color}}"></i>
                        {{element.ano}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="janeiro">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Janeiro </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m01 > 0 ? element.m01 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="fevereiro">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Fevereiro </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m02 > 0 ? element.m02 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="marco">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Março </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m03 > 0 ? element.m03 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="abril">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Abril </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m04 > 0 ? element.m04 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="maio">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Maio </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m05 > 0 ? element.m05 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="junho">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Junho </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m06 > 0 ? element.m06 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="julho">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Julho </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m07 > 0 ? element.m07 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="agosto">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Agosto </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m08 > 0 ? element.m08 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="setembro">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Setembro </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m09 > 0 ? element.m09 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="outubro">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Outubro </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m10 > 0 ? element.m10 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="novembro">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Novembro </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m11 > 0 ? element.m11 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <ng-container matColumnDef="dezembro">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Dezembro </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{(element.m12 > 0 ? element.m12 : 0) |
                    number}} {{element.symbol}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

    </div>
</div>