import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { MatSort } from '@angular/material/sort';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { ReadParam } from '../../../models/params';

export interface PeriodicElement {
	total_vendido: number;
}

@Component({
	selector: 'app-mensal',
	templateUrl: './mensal.component.html',
	styleUrls: ['./mensal.component.scss']
})
export class MensalComponent implements OnInit {

	@Input() fromDate: NgbDate;
	@Input() toDate: NgbDate;

	readParams: ReadParam = { endpoint: 'produto', user: '', fromDate: '', toDate: '' };

	user: any;

	widgetTitle = 'Faturamento do Período';
	// widgetSubTitle = 'Quisque pulvinar aliquam turpis vitae iaculis';

	// Active tab
	activeTab = 'default';

	// Chart data
	Windows: ChartDataSets[] = [
		{
			label: 'Semanal',
			data: Array.from({ length: 25 }, () => Math.floor(Math.random() * 40 + 3)),
			backgroundColor: '#399EFF',
			hoverBackgroundColor: '#1E77CD',
			barThickness: 4
		}
	];

	Linux: ChartDataSets[] = [
		{
			label: 'Mensal',
			data: Array.from({ length: 25 }, () => Math.floor(Math.random() * 40 + 3)),
			backgroundColor: '#FFAF5D',
			hoverBackgroundColor: '#D4832F',
			barThickness: 4
		}
	];

	// Textual Data
	osData = [
		{
			os: 'Últimos 7 dias',
			title: 'Faturamento / Markup',
			value: 0,
			percentage: 0,
			color: 'blue',
			icon: 'line-chart',
			// data: this.Windows,
			up: false
		},
		{
			os: 'Últimos 7 dias',
			title: 'Custo',
			value: 0,
			percentage: 0,
			color: 'red',
			icon: 'line-chart',
			// data: this.Windows,
			up: false
		},
		{
			os: 'Período Informado',
			title: 'Faturamento / Markup',
			value: 0,
			percentage: 0,
			color: 'blue',
			icon: 'line-chart',
			// data: this.Linux,
			up: true
		},
		{
			os: 'Período Informado',
			title: 'Custo',
			value: 0,
			percentage: 0,
			color: 'red',
			icon: 'line-chart',
			// data: this.Linux,
			up: true
		}
	]

	constructor(private crudService: CrudService) { }

	ngOnInit(): void { }

	ngOnChanges() {

		if (this.fromDate && this.toDate && this.fromDate !== null && this.toDate !== null) {

			this.user = localStorage.getItem('auth');
			this.user = JSON.parse(this.user);

			this.readParams.user = this.user.email;
			this.readParams.fromDate = this.fromDate.year + ("00" + this.fromDate.month).slice(-2) + ("00" + this.fromDate.day).slice(-2);
			this.readParams.toDate = this.toDate.year + ("00" + this.toDate.month).slice(-2) + ("00" + this.toDate.day).slice(-2);

			this.readParams.endpoint = 'semanal';
			this.crudService.read(this.readParams)
				.subscribe((response) => {
					this.osData[0].value = parseInt((response[0].total_vendido / 1000).toFixed());
					if (this.osData[1].value > 0) {
						this.osData[0].percentage = this.osData[0].value / this.osData[1].value - 1;
					}
				}, erro => {
					console.error(erro);
				})

			this.readParams.endpoint = 'custosemanal';
			this.crudService.read(this.readParams)
				.subscribe((response) => {
					this.osData[1].value = parseInt((response[0].total_vendido / 1000).toFixed());
					if (this.osData[1].value > 0) {
						this.osData[0].percentage = this.osData[0].value / this.osData[1].value - 1;
					}
				}, erro => {
					console.error(erro);
				})

			this.readParams.endpoint = 'mensal';
			this.crudService.read(this.readParams)
				.subscribe((response) => {
					this.osData[2].value = parseInt((response[0].total_vendido / 1000).toFixed());
					if (this.osData[3].value > 0) {
						this.osData[2].percentage = this.osData[2].value / this.osData[3].value - 1;
					}
				}, erro => {
					console.error(erro);
				})

			this.readParams.endpoint = 'customensal';
			this.crudService.read(this.readParams)
				.subscribe((response) => {
					this.osData[3].value = parseInt((response[0].total_vendido / 1000).toFixed());
					if (this.osData[3].value > 0) {
						this.osData[2].percentage = this.osData[2].value / this.osData[3].value - 1;
					}
				}, erro => {
					console.error(erro);
				})

		}

	}

}
