import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
	searchFocused = false;
	searchValue = '';

	constructor(public service: AppService) {}

	searchReset() {
		this.searchValue = '';
		this.searchFocused = false;
		this.service.searchToggled = false;
	}

	ngOnInit() {}
}
