import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppService {
	// -----------------------
	// Global States
	// -----------------------
	// Theme Layout
	themeLayout: string;

	// Settings
	settingsActive = false;

	// Sidebar
	sidebarActive = false;

	// Mobile Search
	searchToggled = false;

	// Sidebar within pages
	asideMobileActive = false;

	// Sidebar Page
	asidePageActive = false;

	// Page loading
	isLoading = false;

	// -----------------------
	// Re-usable functions
	// -----------------------
	// Scroll to section
	scrollToCategory(id: string) {
		const item = document.getElementById(id);
		const window = document.querySelector('.content__inner');
		const count = item.offsetTop - window.scrollTop - 23;
		window.scrollBy({ top: count, left: 0, behavior: 'smooth' });
	}

	constructor() {}
}
