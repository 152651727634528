import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CrudService } from 'src/app/services/crud.service';
import { ReadParam } from '../../../models/params';
import * as XLSX from 'xlsx';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-estoque-lote',
  templateUrl: './estoque-lote.component.html',
  styleUrls: ['./estoque-lote.component.scss']
})
export class EstoqueLoteComponent implements AfterViewInit {

  @Output() changeData = new EventEmitter<{ dataSource: any[] }>();

  @ViewChild(MatTable) table!: MatTable<any>;

  readParams: ReadParam = { endpoint: 'estoquelote', user: '', fromDate: '', toDate: '' };

  displayedColumns: string[] = [
    'codigo',
    'descricao',
    'unidade_medida',
    'ean',
    'fabricante',
    'lote',
    'vencimento',
    'saldo_estoque'
  ];

  dataSource: any[] = [];

  @ViewChild(MatSort) sort!: MatSort;

  user: any;

  constructor(private crudService: CrudService) { }

  ngAfterViewInit() {

    this.user = localStorage.getItem('auth');
    this.user = JSON.parse(this.user);

    this.readParams.user = this.user.email;

    this.crudService.read(this.readParams)
      .subscribe((response) => {

        this.dataSource = response;
        this.table.renderRows();

        this.changeData.emit({ dataSource: this.dataSource });

      }, erro => {
        console.error(erro);
      })

  }

  ngOnChanges() {

    this.user = localStorage.getItem('auth');
    this.user = JSON.parse(this.user);

    this.readParams.user = this.user.email;

    this.crudService.read(this.readParams)
      .subscribe((response) => {

        this.dataSource = response;
        this.table.renderRows();

        this.changeData.emit({ dataSource: this.dataSource });

      }, erro => {
        console.error(erro);
      })

  }

  exportexcel(): void {

    const fileName = 'EstoqueProdutoLote.xlsx';

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Estoque Produto Lote');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }

}
