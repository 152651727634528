import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from '../../../app.service';

@Component({
	selector: 'app-layout1',
	templateUrl: './layout1.component.html',
	styleUrls: ['./layout1.component.scss']
})
export class Layout1Component implements OnInit {
	layoutParam = 'layout';

	constructor(public service: AppService, private route: ActivatedRoute, private router: Router) {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((params) => {
			service.sidebarActive = route.snapshot.firstChild.data[this.layoutParam] === 'full';
			service.themeLayout = route.snapshot.firstChild.data[this.layoutParam] === 'full' ? 'full' : '';
		});
	}

	ngOnInit() {}
}
