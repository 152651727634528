<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">Faturamento por Origem do Pedido</h4>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="chart mb-4">
            <div class="chart__canvas chart__canvas--lg">
                <canvas #canvas baseChart [chartType]="chartType" [datasets]="chartData" [options]="chartOptions"
                    [labels]="chartLabel"></canvas>
                <div class="chart__numbers">
                    <div class="h4 text-headings">{{total}}K</div>
                    <div class="text-muted">Total Vendido</div>
                </div>
            </div>
        </div>

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="origem">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Origem do Pedido </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex-grow-1 text-headings d-flex align-items-center">
                        <i class="w-10px h-10px rounded-circle mr-3" style="background-color: {{element.color}}"></i>
                        {{element.origem}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="total_custo">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Custo </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_custo | currency}} </td>
            </ng-container>

            <ng-container matColumnDef="total_vendido">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Vendido </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_vendido | currency}} </td>
            </ng-container>

            <ng-container matColumnDef="markup_medio">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> % Markup </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{element.markup_medio | percent}} </td>
            </ng-container>

            <ng-container matColumnDef="perc_faturamento">
                <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> % Vendas </th>
                <td class='format-number' mat-cell *matCellDef="let element"> {{element.perc_faturamento | percent}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

    </div>

</div>