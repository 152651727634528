import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Output() clickClose = new EventEmitter;

  user: any;

  constructor() { }

  ngOnInit(): void {

    this.user = localStorage.getItem('auth');
    this.user = JSON.parse(this.user);

  }

  onClose() {

    this.clickClose.emit();

  }

}
