<div class="toolbar">
	<h2 class="toolbar__title">Dashboard</h2>
	<ol class="breadcrumb ml-4 d-none d-sm-flex">
		<li class="breadcrumb-item">Relatórios</li>
		<li class="breadcrumb-item">Estoque</li>
	</ol>

	<i class="ml-auto"></i>

	<!-- <div class="nav nav--hover nav--icon nav--compact mr-n2 align-items-center">
		<i class="toolbar__divider mx-2 d-none d-md-inline"></i>
		<a class="nav-link" (click)="clickExportExcel()">
			<i class="zwicon zwicon-download"></i><span>Download</span>
		</a>
	</div> -->
</div>
