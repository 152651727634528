<div class="login">
	<div class="login__form">

		<h5 class="mb-2">Welcome to Venda+</h5>
		<p class="mb-4">Por favor informe usuário e senha para continuar</p>

		<div class="login__field">
			<i class="zwicon zwicon-user-follow"></i>
			<input id="username" placeholder="Usuário" [(ngModel)]="user" />
		</div>
		<div class="login__field">
			<i class="zwicon zwicon-lock"></i>
			<input id="password" type="password"  placeholder="Senha" [(ngModel)]="pass" />
		</div>
		<!-- <div class="login__field">
			<i class="zwicon zwicon-cable-usb"></i>
			<select class="custom-select" style="border: 0px;" [(ngModel)]="origin">
				<option>Interno</option>
				<option>Externo</option>
			</select>
		</div> -->

		<button class="login__btn mt-5" (click)="login()">
			<span *ngIf="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
			{{action}}</button>

		<div class="text-center mt-4">
			<a routerLink="/misc/sample-pages/register" class="link-muted d-block mb-1">Criar nova conta</a>
			<a routerLink="/misc/sample-pages/forgot-password" class="link-muted d-block">Esqueci minha senha</a>
		</div>
		
		<div *ngIf="alert" id="float-banner" class="float-banner">
			<ngb-alert [dismissible]="true" type="danger" (close)="close()">
				{{message}}
			</ngb-alert>
		</div>

	</div>
</div>

