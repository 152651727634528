<div class="search__inner">
	<div class="search__helper" [class.search__helper--focused]="searchFocused">
		<i class="zwicon zwicon-search"></i>
		<i class="zwicon zwicon-arrow-left" (click)="searchReset()"></i>
	</div>
	<input
		#search
		type="text"
		class="search__input"
		value=""
		placeholder="Search for files, reports and records"
		[(ngModel)]="searchValue"
		(focus)="searchFocused = true"
		(blur)="searchReset()"
	/>
</div>
