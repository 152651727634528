import { Injectable } from '@angular/core';
import { ChartLegendOptions } from 'chart.js';

@Injectable({
	providedIn: 'root'
})
export class ChartService {
	// Legend
	legend = {
		fontSize: '12px',
		fontFamily: 'Roboto',
		labels: {
			colors: ['#799FBA']
		},
		markers: {
			width: 10,
			height: 10,
			radius: 50
		}
	};

	// Grid
	grid = {
		show: true,
		borderColor: '#e7f0f9',
		padding: {
			bottom: 15,
			top: 0
		}
	};

	// Label
	labels = {
		style: {
			fontFamily: 'Roboto',
			colors: '#799FBA',
			fontSize: '10px'
		}
	};

	// Crosshairs
	crosshairs = {
		show: true,
		stroke: {
			color: '#C0D1E2'
		}
	};

	//  -----

	// Chart Colors
	chartColors = {
		blue: {
			base: '#35A0FC',
			hover: '#2289E1'
		},
		red: {
			base: '#FF6178',
			hover: '#D13A50'
		},
		green: {
			base: '#33E7A6',
			hover: '#30C58F'
		},
		amber: {
			base: '#FEBC41',
			hover: '#E8AB38'
		}
	};

	// Tooltips
	tooltips = {
		titleFontFamily: 'Roboto',
		titleFontStyle: 'normal',
		titleFontColor: '#deeeff',
		bodyFontFamily: 'Roboto',
		bodyFontSize: 11,
		bodyFontColor: '#B0C6DD',
		xPadding: 15,
		yPadding: 15,
		cornerRadius: 3,
		multiKeyBackground: 'transparent',
		backgroundColor: '#192f40',
		displayColors: false
	};

	// Ticks
	ticksY = {
		beginAtZero: true,
		fontFamily: 'Roboto',
		fontColor: '#799FBA',
		fontSize: 10,
		padding: 10
	};

	ticksX = {
		beginAtZero: true,
		fontFamily: 'Roboto',
		fontColor: '#799FBA',
		fontSize: 10
	};

	// GridLines
	gridLines = {
		color: '#e7f0f9',
		zeroLineColor: '#e7f0f9',
		drawBorder: false,
		tickMarkLength: 0
	};

	// Layout
	layout = {
		padding: {
			top: 20
		}
	};

	// Legend
	legends: ChartLegendOptions = {
		position: 'bottom',
		labels: {
			usePointStyle: true,
			boxWidth: 6,
			fontColor: '#799FBA',
			fontSize: 11,
			fontFamily: 'Roboto'
		}
	};

	constructor() {}
}
