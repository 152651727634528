import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // url = 'http://www.vendamaisnaweb.com.br/api/producao/v1/sfa';

  url = 'https://sfa.maispedido.com.br/api';

  constructor(private httpClient: HttpClient) { }

  auth(user: string, pass: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'BASIC ' + btoa(user + ":" + pass)
      })
    };

    return this.httpClient.get<any>(this.url + '/login.php?username=' + user + '&password=' + pass, httpOptions);

  }

}
