<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">Estoque de Produtos por Lote</h4>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="table-container mat-elevation-z8">

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="codigo">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.codigo}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="descricao">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.descricao}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="unidade_medida">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Un.Med </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.unidade_medida}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ean">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> EAN </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.ean}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="fabricante">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Fabricante </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.fabricante}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lote">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Lote </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.lote}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="vencimento">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Vencimento </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.vencimento}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="saldo_estoque">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Estoque </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.saldo_estoque | number}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

</div>