import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nota-fiscal-entrada',
  templateUrl: './nota-fiscal-entrada.component.html',
  styleUrls: ['./nota-fiscal-entrada.component.scss']
})
export class NotaFiscalEntradaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
