import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'app-top-nav',
	templateUrl: './top-nav.component.html',
	styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
	// Order Notifications
	orderNotifications: any = [
		{
			title: 'New sales order received',
			status: 'unread',
			id: '#204',
			time: '10 minutes ago',
			icon: 'package',
			color: 'red'
		},
		{
			title: 'New user account registered',
			status: 'unread',
			id: '#3102',
			time: '23 minutes ago',
			icon: 'user-circle',
			color: 'green'
		},
		{
			title: 'New product review received',
			status: 'read',
			id: '#8973',
			time: '48 minutes ago',
			icon: 'chat',
			color: 'blue'
		},
		{
			title: 'New product review received',
			status: 'read',
			id: '#8972',
			time: '50 minutes ago',
			icon: 'chat',
			color: 'blue'
		},
		{
			title: 'New sales order received',
			status: 'read',
			id: '#203',
			time: '55 minutes ago',
			icon: 'package',
			color: 'red'
		},
		{
			title: 'Review unpaid orders',
			status: 'read',
			id: '#765',
			time: '2 hours ago',
			icon: 'checkmark-circle',
			color: 'amber'
		}
	];

	// Support notifications
	supportNotifications: any = [
		{
			title: 'New issue filed by customer',
			status: 'unread',
			id: '#721',
			time: '10 seconds ago',
			icon: 'add-to-list',
			color: 'blue'
		},
		{
			title: 'New comment received',
			status: 'unread',
			id: '#976',
			time: '5 minutes ago',
			icon: 'chat',
			color: 'lime'
		},
		{
			title: 'Thread responded and closed',
			status: 'unread',
			id: '#45',
			time: '2 hours ago',
			icon: 'checkmark-square',
			color: 'orange'
		},
		{
			title: 'Thread re-opened by administrator',
			status: 'unread',
			id: '#8972',
			time: '5 hours ago',
			icon: 'add-note',
			color: 'purple'
		},
		{
			title: 'New support request received',
			status: 'read',
			id: '#432',
			time: '10 hours ago',
			icon: 'add-item-alt',
			color: 'deep-orange'
		},
		{
			title: 'New issue filed by customer',
			status: 'read',
			id: '#720',
			time: '2 days ago',
			icon: 'add-to-list',
			color: 'blue'
		}
	];

	// Mails
	mails: any = [
		{
			image: '15.jpg',
			user: 'David Belle',
			content: 'Cum sociis natoque penatibus et magnis dis parturient montes',
			time: '10:02 AM',
			status: 'unread'
		},
		{
			cap: 'AW',
			color: 'red',
			user: 'Aaron Warner',
			content: 'Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel',
			time: '02:30 PM',
			status: 'read'
		},
		{
			cap: 'FM',
			color: 'blue',
			user: 'Fredric Mitchell Jr.',
			content: 'Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies',
			time: 'Tue',
			status: 'read'
		},
		{
			image: '12.jpg',
			user: 'Glenn Jecobs',
			content:
				'Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque',
			time: 'Mon',
			status: 'unread'
		},
		{
			image: '11.jpg',
			user: 'Bill Phillips',
			content: 'Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat',
			time: 'Fri',
			status: 'unread'
		},
		{
			image: '4.jpg',
			user: 'Alice Margit',
			content: 'Aenean lacinia bibendum nulla sed consectetur',
			time: 'May 10',
			status: 'read'
		},
		{
			cap: 'VH',
			color: 'amber',
			user: 'Van Hasnon',
			content: 'Nullam iolor ieonibh ultricies vehicula magna',
			time: 'May 09',
			status: 'unread'
		},
		{
			cap: 'IA',
			color: 'purple',
			user: 'Iason Antonius',
			content: 'Maecenas faucibus mollis interdum Maecenas sed diam eget risus varius blandit sit amet non magna',
			time: 'May 08',
			status: 'read'
		}
	];

	// Tasks
	tasks = [
		{
			name: 'Page speed test for parent website',
			completed: 95,
			color: 'primary'
		},
		{
			name: 'Project version 3.2.1 release',
			completed: '80',
			color: 'success'
		},
		{
			name: 'Severe bug fixes',
			completed: '20',
			color: 'warning'
		},
		{
			name: 'UI design for proposed landing page',
			completed: '60',
			color: 'danger'
		},
		{
			name: 'Blog article for the month of August',
			completed: '80',
			color: 'info'
		}
	];

	constructor(public service: AppService) {}

	ngOnInit() {}
}
