<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">Notas Fiscais</h4>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="table-container">

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="fornecedor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Fornecedor </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.fornecedor}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="emissao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Emissão </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.emissao}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nota">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nota Fiscal </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.nota}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="serie">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Série </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.serie}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="valor">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.valor | currency}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

</div>