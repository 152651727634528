<div class="modal-header">
	<!-- <h4 class="modal-title">Meu Perfil</h4> -->
</div>
<div class="modal-body">

	<div class="text-center">
		<label for="contact-avatar-new">
			<img class="rounded-circle w-100px h-100px" src="./assets/img/misc/profile-picture-default.svg" alt="" />
			<!-- <input class="d-none" id="contact-avatar-new" type="file" /> -->
		</label>
	</div>

	<div class="form-row">
		<div class="col-md-12">
			<div class="form-group">
				<label class="form-label" for="add-contact-name">Nome</label>
				<input id="name" type="text" class="form-control" [(ngModel)]="user.nome" readonly/>
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<label class="form-label" for="add-contact-phone">Empresa</label>
				<input id="company" type="text" class="form-control" [(ngModel)]="user.nomfil" readonly/>
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<label class="form-label" for="add-contact-email">E-mail</label>
				<input id="email" type="email" class="form-control" [(ngModel)]="user.email" readonly/>
			</div>
		</div>
		<div class="col-md-12">
			<div class="form-group">
				<label class="form-label" for="add-contact-phone">Função</label>
				<input id="job" type="text" class="form-control" [(ngModel)]="user.cargo" readonly/>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button class="btn btn-light" (click)="onClose()">Fechar</button>
</div>