<div class="layout" [ngClass]="{
		'layout--toggled': service.sidebarActive,
		'layout--aside': service.themeLayout === 'full',
		'layout--aside-toggled': service.asidePageActive
	}">
	<app-sidebar [class.sidebar-toggled]="service.sidebarActive"></app-sidebar>
	<div *ngIf="service.sidebarActive" class="backdrop backdrop--sidebar d-xl-none"
		(click)="service.sidebarActive = false"></div>

	<div class="main">
		<app-header class="header"></app-header>
		<router-outlet></router-outlet>
	</div>
</div>