<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">Faturamento por Item da Nota Fiscal</h4>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="table-container">

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="emissao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Emissão </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.emissao}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nota_fiscal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nota Fiscal </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.nota_fiscal}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.tipo}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="produto">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Produto </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.produto}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="descricao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.descricao}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="marca">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Marca </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.marca}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ean">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> EAN </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.ean}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="qtde_vendido">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Quantidade </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.qtde_vendido | number}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="unidade_medida">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Un.Med. </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.unidade_medida}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="custo">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Custo </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.custo | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="preco">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Preço </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.preco | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_custo">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Custo </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_custo | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_vendido">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Vendido </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_vendido | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="markup">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> % Mark </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.markup | percent}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="vendedor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.vendedor}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.cliente}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cnpj">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CNPJ </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.cnpj}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="bairro">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Bairro </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.bairro}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cidade">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cidade </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.cidade}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="uf">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> UF </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.uf}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tipo_cliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Cliente </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.tipo_cliente}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="origem">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Origem </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.origem}}
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

</div>