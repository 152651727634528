import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

	auth: any;

	userInfo: any = {
		name: '',
		email: '',
		img: './assets/demo/img/contacts/user_.png'
	};

	constructor(private router: Router, public modalService: NgbModal) { }

	ngOnInit() {

		this.auth = localStorage.getItem('auth');
		this.auth = JSON.parse(this.auth);

		this.userInfo.name = this.auth.nome;
		this.userInfo.email = this.auth.email;

	}

	logout() {

		localStorage.removeItem('auth');
		this.router.navigate(['login']);

	}

	openProfile(content) {
		this.modalService.open(content, {
			centered: true,
			backdrop: 'static'
		});		
	}

}
