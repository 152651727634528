<app-reports-toolbar (changeDate)="changeDate($event)" (exportExcel)="exportExcel()"></app-reports-toolbar>

<div class="content__inner">

    <app-mensal [fromDate]="fromDate" [toDate]="toDate" ></app-mensal>            

    <app-anual [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataAnual($event)"></app-anual>

    <app-tipo-cliente [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataTipo($event)"></app-tipo-cliente>

    <app-origem [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataOrigem($event)"></app-origem>

    <app-fabricante [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataFabricante($event)"></app-fabricante>

    <app-vendedor [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataVendedor($event)"></app-vendedor>

    <app-produto [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataProduto($event)"></app-produto>

    <app-item-nota-fiscal [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataItem($event)"></app-item-nota-fiscal>

</div>

