<header class="header">
	<app-header-toggle></app-header-toggle>

	<!-- <app-search class="search" [class.search--toggled]="service.searchToggled"></app-search> -->

	<div class="header__loader text-primary spinner-border" [class.active]="service.isLoading"></div>

	<app-top-nav></app-top-nav>
	<!-- <app-language class="d-none d-sm-block"></app-language> -->
	<app-user></app-user>
	<app-settings class="settings d-none d-sm-block" [class.settings--active]="service.settingsActive"> </app-settings>

	<!-- Backdrop for settings panel -->
	<div
		*ngIf="service.settingsActive"
		class="backdrop backdrop--settings"
		(click)="service.settingsActive = false"
	></div>
</header>
