<div class="user" ngbDropdown>
	<img ngbDropdownToggle class="user__img" [src]="userInfo.img" [alt]="userInfo.name" />
	<div ngbDropdownMenu class="dropdown-menu--icon">
		<div class="dropdown-header bg-gradient-perano">{{userInfo.name}}</div>

		<a ngbDropdownItem (click)="openProfile(add)"><i class="zwicon zwicon-user-circle"></i>Perfil</a>
		<!-- <a ngbDropdownItem routerLink=""><i class="zwicon zwicon-coin"></i>Billing</a>
		<a ngbDropdownItem routerLink=""><i class="zwicon zwicon-cog"></i>Settings</a> -->
		<a ngbDropdownItem (click)="logout()"><i class="zwicon zwicon-sign-out"></i>Sair</a>
	</div>
</div>

<ng-template #add let-modal>
	<app-profile (clickClose)="modal.dismiss()"></app-profile>
</ng-template>
