import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @HostBinding('class') class = 'content';

  fromDate: Subject<NgbDate> = new Subject<NgbDate>();
  toDate: Subject<NgbDate> = new Subject<NgbDate>();
  dataSourceAnual: any[] = [];
  dataSourceTipo: any[] = [];
  dataSourceOrigem: any[] = [];
  dataSourceFabricante: any[] = [];
  dataSourceVendedor: any[] = [];
  dataSourceProduto: any[] = [];
  dataSourceItem: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  changeDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
  }

  changeDataAnual(event) {    
    this.dataSourceAnual = event.dataSource;
  }

  changeDataTipo(event) {
    this.dataSourceTipo = event.dataSource;
  }

  changeDataOrigem(event) {
    this.dataSourceOrigem = event.dataSource;
  }

  changeDataFabricante(event) {
    this.dataSourceFabricante = event.dataSource;
  }

  changeDataVendedor(event) {
    this.dataSourceVendedor = event.dataSource;
  }

  changeDataProduto(event) {
    this.dataSourceProduto = event.dataSource;
  }

  changeDataItem(event) {
    this.dataSourceItem = event.dataSource;
  }

  exportExcel() {

		const fileName = 'Faturamento.xlsx';
		const wb: XLSX.WorkBook = XLSX.utils.book_new();

		let ws: XLSX.WorkSheet;

    ws = XLSX.utils.json_to_sheet(this.dataSourceItem);
		XLSX.utils.book_append_sheet(wb, ws, 'Dados');

		ws = XLSX.utils.json_to_sheet(this.dataSourceProduto);
		XLSX.utils.book_append_sheet(wb, ws, 'Produto');

		ws = XLSX.utils.json_to_sheet(this.dataSourceVendedor);
		XLSX.utils.book_append_sheet(wb, ws, 'Vendedor');

    ws = XLSX.utils.json_to_sheet(this.dataSourceTipo);
		XLSX.utils.book_append_sheet(wb, ws, 'Tipo de CLiente');

    ws = XLSX.utils.json_to_sheet(this.dataSourceOrigem);
		XLSX.utils.book_append_sheet(wb, ws, 'Origem');

    ws = XLSX.utils.json_to_sheet(this.dataSourceFabricante);
		XLSX.utils.book_append_sheet(wb, ws, 'Fabricante');

    ws = XLSX.utils.json_to_sheet(this.dataSourceAnual);
		XLSX.utils.book_append_sheet(wb, ws, 'Anual');

		XLSX.writeFile(wb, fileName);

  }

}
