import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-language',
	templateUrl: './language.component.html',
	styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
	languages: any = {
		current: 'English',
		list: [
			{
				language: 'French',
				flag: 'france'
			},
			{
				language: 'German',
				flag: 'germany'
			},
			{
				language: 'Japanese',
				flag: 'japan'
			},
			{
				language: 'Chinese',
				flag: 'china'
			}
		]
	};

	constructor() {}

	ngOnInit() {}
}
