import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { ReadParam } from '../../../models/params';
import * as XLSX from 'xlsx';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-documento-entrada',
  templateUrl: './documento-entrada.component.html',
  styleUrls: ['./documento-entrada.component.scss']
})
export class DocumentoEntradaComponent implements OnInit {

  @Output() changeData = new EventEmitter<{dataSource: any[]}>();

  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;

  @ViewChild(MatTable) table!: MatTable<any>;

  readParams: ReadParam = { endpoint: 'documentoentrada', user: '', fromDate: '', toDate: '' };

  displayedColumns: string[] = [
    'fornecedor', 
    'emissao', 
    'nota', 
    'serie', 
    'valor'
  ];

  dataSource: any[] = [];

  @ViewChild(MatSort) sort!: MatSort;

  user: any;

  constructor(private crudService: CrudService) { }

  ngOnInit(): void { }

  ngOnChanges() {

    if (this.fromDate && this.toDate && this.fromDate !== null && this.toDate !== null) {

      this.user = localStorage.getItem('auth');
      this.user = JSON.parse(this.user);

      this.readParams.user = this.user.email;
      this.readParams.fromDate = this.fromDate.year + ("00" + this.fromDate.month).slice(-2) + ("00" + this.fromDate.day).slice(-2);
      this.readParams.toDate = this.toDate.year + ("00" + this.toDate.month).slice(-2) + ("00" + this.toDate.day).slice(-2);

      this.crudService.read(this.readParams)
        .subscribe((response) => {

          this.dataSource = response;
          this.table.renderRows();

          this.changeData.emit({dataSource: this.dataSource});
    
        }, erro => {
          console.error(erro);
        })

    }

  }

  exportexcel(): void {

		const fileName = 'NotaEntrada.xlsx';

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Notas');

		/* save to file */
		XLSX.writeFile(wb, fileName);

	}

}
