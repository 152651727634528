import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { Layout1Component } from './components/layouts/layout1/layout1.component';
import { Layout2Component } from './components/layouts/layout2/layout2.component';
import { DashEstoqueComponent } from './reports/estoque/dash-estoque/dash-estoque.component';
import { DashboardComponent } from './reports/faturamento/dashboard/dashboard.component';
import { DashFinanceiroComponent } from './reports/financeiro/dash-financeiro/dash-financeiro.component';

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{
		path: '',
		component: Layout1Component, canActivate: [AuthGuard],
		children: [
			// -------------------------------------
			// 1. DASHBOARDS
			// -------------------------------------
			// 1.1 Website Analytics
			{
				path: 'reports/faturamento',
				component: DashboardComponent,
				// pathMatch: 'full'
			},
			{
				path: 'reports/estoque',
				component: DashEstoqueComponent,
				// pathMatch: 'full'
			},
			{
				path: 'reports/financeiro',
				component: DashFinanceiroComponent,
				// pathMatch: 'full'
			},
			{ path: '**', redirectTo: 'reports/faturamento' }
	// 		{
	// 			path: 'dashboards/website-analytics',
	// 			loadChildren: () =>
	// 				import('./pages/dashboards/website-analytics/website-analytics.module').then(
	// 					(m) => m.WebsiteAnalyticsModule
	// 				)
	// 		},
	// 		{
	// 			path: 'dashboards/e-commerce',
	// 			loadChildren: () =>
	// 				import('./pages/dashboards/e-commerce/e-commerce.module').then((m) => m.ECommerceModule)
	// 		},
	// 		{
	// 			path: 'reports/dashboard', component: DashboardComponent
	// 		},
	// 		// -------------------------------------
	// 		// 2. APPLICATION VIEWS
	// 		// -------------------------------------
	// 		// 2.1 User
	// 		{
	// 			path: 'application-views/user/profile',
	// 			loadChildren: () => import('./pages/app-views/user/profile/profile.module').then((m) => m.ProfileModule)
	// 		},
	// 		{
	// 			path: 'application-views/user/timeline',
	// 			loadChildren: () =>
	// 				import('./pages/app-views/user/timeline/timeline.module').then((m) => m.TimelineModule)
	// 		},
	// 		{
	// 			path: 'application-views/user/preferences',
	// 			loadChildren: () =>
	// 				import('./pages/app-views/user/preferences/preferences.module').then((m) => m.PreferencesModule)
	// 		},
	// 		{
	// 			path: 'application-views/user/photos',
	// 			loadChildren: () =>
	// 				import('./pages/app-views/user/photos/photos.module').then((m) => m.UserPhotosModule)
	// 		},
	// 		{
	// 			path: 'application-views/user/connections',
	// 			loadChildren: () =>
	// 				import('./pages/app-views/user/connections/connections.module').then((m) => m.UserConnectionsModule)
	// 		},
	// 		// 2.2 File Manager
	// 		{
	// 			path: 'application-views/files',
	// 			loadChildren: () => import('./pages/app-views/files/files.module').then((m) => m.FilesModule)
	// 		},
	// 		// 2.3 Contacts
	// 		{
	// 			path: 'application-views/contacts',
	// 			loadChildren: () => import('./pages/app-views/contacts/contacts.module').then((m) => m.ContactsModule),
	// 			data: {
	// 				layout: 'full'
	// 			}
	// 		},
	// 		// 2.4 Messages
	// 		{
	// 			path: 'application-views/messages',
	// 			loadChildren: () => import('./pages/app-views/messages/messages.module').then((m) => m.MessagesModule)
	// 		},
	// 		// 2.5 Mail
	// 		{
	// 			path: 'application-views/mail',
	// 			loadChildren: () => import('./pages/app-views/mail/mail.module').then((m) => m.MailModule),
	// 			data: {
	// 				layout: 'full'
	// 			}
	// 		},
	// 		// 2.6 Photos
	// 		{
	// 			path: 'application-views/photos',
	// 			loadChildren: () => import('./pages/app-views/photos/photos.module').then((m) => m.PhotosModule)
	// 		},
	// 		// 2.7 Todolists
	// 		{
	// 			path: 'application-views/todo',
	// 			loadChildren: () => import('./pages/app-views/todo/todo.module').then((m) => m.TodoModule),
	// 			data: {
	// 				layout: 'full'
	// 			}
	// 		},
	// 		// 2.8 Calendar
	// 		{
	// 			path: 'application-views/calendar',
	// 			loadChildren: () => import('./pages/app-views/calendar/calendar.module').then((m) => m.CalendarModule)
	// 		},
	// 		// -------------------------------------
	// 		// 3. SAMPLE PAGES
	// 		// -------------------------------------
	// 		// 3.1 Invoice
	// 		{
	// 			path: 'misc/sample-pages/invoice',
	// 			loadChildren: () => import('./pages/sample-pages/invoice/invoice.module').then((m) => m.InvoiceModule)
	// 		},
	// 		// 3.2 Pricing Table
	// 		{
	// 			path: 'misc/sample-pages/pricing-table',
	// 			loadChildren: () =>
	// 				import('./pages/sample-pages/pricing-table/pricing-table.module').then((m) => m.PricingTableModule)
	// 		},
	// 		// 3.3 Search Results
	// 		{
	// 			path: 'misc/sample-pages/search-results',
	// 			loadChildren: () =>
	// 				import('./pages/sample-pages/search-results/search-results.module').then(
	// 					(m) => m.SearchResultsModule
	// 				)
	// 		},
	// 		// 3.4 Issue Tracker
	// 		{
	// 			path: 'misc/sample-pages/issue-tracker',
	// 			loadChildren: () =>
	// 				import('./pages/sample-pages/issue-tracker/issue-tracker.module').then((m) => m.IssueTrackerModule)
	// 		},
	// 		// 3.5 FAQ
	// 		{
	// 			path: 'misc/sample-pages/faq',
	// 			loadChildren: () => import('./pages/sample-pages/faq/faq.module').then((m) => m.FaqModule)
	// 		},
	// 		// 3.6 Team
	// 		{
	// 			path: 'misc/sample-pages/team',
	// 			loadChildren: () => import('./pages/sample-pages/team/team.module').then((m) => m.TeamModule)
	// 		},
	// 		// -------------------------------------
	// 		// 4. Content
	// 		// -------------------------------------
	// 		// 4.1 Typography
	// 		{
	// 			path: 'misc/content/typography',
	// 			loadChildren: () => import('./pages/misc/typography/typography.module').then((m) => m.TypographyModule)
	// 		},
	// 		// 4.2 Code
	// 		{
	// 			path: 'misc/content/code',
	// 			loadChildren: () => import('./pages/misc/code/code.module').then((m) => m.CodeModule)
	// 		},
	// 		// 4.3 Code
	// 		{
	// 			path: 'misc/content/images',
	// 			loadChildren: () => import('./pages/misc/images/images.module').then((m) => m.ImagesModule)
	// 		},
	// 		// 4.4 Tables
	// 		{
	// 			path: 'misc/content/tables',
	// 			loadChildren: () => import('./pages/misc/tables/tables.module').then((m) => m.TablesModule)
	// 		},
	// 		// -------------------------------------
	// 		// 5. Icons
	// 		// -------------------------------------
	// 		{
	// 			path: 'misc/icons',
	// 			loadChildren: () => import('./pages/misc/icons/icons.module').then((m) => m.IconsModule)
	// 		},
	// 		// -------------------------------------
	// 		// 6. Components
	// 		// -------------------------------------
	// 		// 6.1 Accordion
	// 		{
	// 			path: 'misc/components/accordion',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/accordion/accordion.module').then((m) => m.AccordionModule)
	// 		},
	// 		// 6.2 Alert
	// 		{
	// 			path: 'misc/components/alert',
	// 			loadChildren: () => import('./pages/misc/components/alert/alert.module').then((m) => m.AlertModule)
	// 		},
	// 		// 6.3 Buttons
	// 		{
	// 			path: 'misc/components/buttons',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/buttons/buttons.module').then((m) => m.ButtonsModule)
	// 		},
	// 		// 6.4 Carousel
	// 		{
	// 			path: 'misc/components/carousel',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/carousel/carousel.module').then((m) => m.CarouselModule)
	// 		},
	// 		// 6.5 Collapse
	// 		{
	// 			path: 'misc/components/collapse',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/collapse/collapse.module').then((m) => m.CollapseModule)
	// 		},
	// 		// 6.6 Datepicker
	// 		{
	// 			path: 'misc/components/datepicker',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/datepicker/datepicker.module').then((m) => m.DatepickerModule)
	// 		},
	// 		// 6.7 Dropdown
	// 		{
	// 			path: 'misc/components/dropdown',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/dropdown/dropdown.module').then((m) => m.DropdownModule)
	// 		},
	// 		// 6.8 Modal
	// 		{
	// 			path: 'misc/components/modal',
	// 			loadChildren: () => import('./pages/misc/components/modal/modal.module').then((m) => m.ModalModule)
	// 		},
	// 		// 6.9 Tab
	// 		{
	// 			path: 'misc/components/tab',
	// 			loadChildren: () => import('./pages/misc/components/tab/tab.module').then((m) => m.TabModule)
	// 		},
	// 		// 6.10 Pagination
	// 		{
	// 			path: 'misc/components/pagination',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/pagination/pagination.module').then((m) => m.PaginationModule)
	// 		},
	// 		// 6.11 Popover
	// 		{
	// 			path: 'misc/components/popover',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/popover/popover.module').then((m) => m.PopoverModule)
	// 		},
	// 		// 6.12 Progressbar
	// 		{
	// 			path: 'misc/components/progressbar',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/progressbar/progressbar.module').then((m) => m.ProgressbarModule)
	// 		},
	// 		// 6.13 Ratings
	// 		{
	// 			path: 'misc/components/ratings',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/ratings/ratings.module').then((m) => m.RatingsModule)
	// 		},
	// 		// 6.14 Timepicker
	// 		{
	// 			path: 'misc/components/timepicker',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/timepicker/timepicker.module').then((m) => m.TimepickerModule)
	// 		},
	// 		// 6.15 Toast
	// 		{
	// 			path: 'misc/components/toast',
	// 			loadChildren: () => import('./pages/misc/components/toast/toast.module').then((m) => m.ToastModule)
	// 		},
	// 		// 6.16 Tooltips
	// 		{
	// 			path: 'misc/components/tooltips',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/tooltips/tooltips.module').then((m) => m.TooltipsModule)
	// 		},
	// 		// 6.17 Typeahead
	// 		{
	// 			path: 'misc/components/typeahead',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/typeahead/typeahead.module').then((m) => m.TypeaheadModule)
	// 		},
	// 		// 6.18 Badge
	// 		{
	// 			path: 'misc/components/badge',
	// 			loadChildren: () => import('./pages/misc/components/badge/badge.module').then((m) => m.BadgeModule)
	// 		},
	// 		// 6.19 Breadcrumbs
	// 		{
	// 			path: 'misc/components/breadcrumbs',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/breadcrumbs/breadcrumbs.module').then((m) => m.BreadcrumbsModule)
	// 		},
	// 		// 6.20 Static Buttons
	// 		{
	// 			path: 'misc/components/static-buttons',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/static-buttons/static-buttons.module').then(
	// 					(m) => m.StaticButtonsModule
	// 				)
	// 		},
	// 		// 6.21 Button Groups
	// 		{
	// 			path: 'misc/components/button-groups',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/button-group/button-group.module').then((m) => m.ButtonGroupModule)
	// 		},
	// 		// 6.22 Cards
	// 		{
	// 			path: 'misc/components/cards',
	// 			loadChildren: () => import('./pages/misc/components/cards/cards.module').then((m) => m.CardsModule)
	// 		},
	// 		// 6.23 Input Groups
	// 		{
	// 			path: 'misc/components/input-groups',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/input-groups/input-groups.module').then((m) => m.InputGroupsModule)
	// 		},
	// 		// 6.24 Jumbotron
	// 		{
	// 			path: 'misc/components/jumbotron',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/jumbotron/jumbotron.module').then((m) => m.JumbotronModule)
	// 		},
	// 		// 6.25 List group
	// 		{
	// 			path: 'misc/components/list-group',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/list-group/list-group.module').then((m) => m.ListGroupModule)
	// 		},
	// 		// 6.26 Media Object
	// 		{
	// 			path: 'misc/components/media-object',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/media-object/media-object.module').then((m) => m.MediaObjectModule)
	// 		},
	// 		// 6.27 Navs
	// 		{
	// 			path: 'misc/components/navs',
	// 			loadChildren: () => import('./pages/misc/components/navs/navs.module').then((m) => m.NavsModule)
	// 		},
	// 		// 6.28 Spinners
	// 		{
	// 			path: 'misc/components/spinners',
	// 			loadChildren: () =>
	// 				import('./pages/misc/components/spinners/spinners.module').then((m) => m.SpinnersModule)
	// 		},
	// 		// -------------------------------------
	// 		// 7. Forms
	// 		// -------------------------------------
	// 		// 7.1 Form elements
	// 		{
	// 			path: 'misc/forms/form-elements',
	// 			loadChildren: () =>
	// 				import('./pages/misc/forms/form-elements/form-elements.module').then((m) => m.FormElementsModule)
	// 		},
	// 		// 7.2 Form components
	// 		{
	// 			path: 'misc/forms/form-components',
	// 			loadChildren: () =>
	// 				import('./pages/misc/forms/form-components/form-components.module').then(
	// 					(m) => m.FormComponentsModule
	// 				)
	// 		},
	// 		// 7.3 Form layouts
	// 		{
	// 			path: 'misc/forms/form-layouts',
	// 			loadChildren: () =>
	// 				import('./pages/misc/forms/form-layouts/form-layouts.module').then((m) => m.FormLayoutsModule)
	// 		},
	// 		// 7.4 Form validation
	// 		{
	// 			path: 'misc/forms/form-validation',
	// 			loadChildren: () =>
	// 				import('./pages/misc/forms/form-validation/form-validation.module').then(
	// 					(m) => m.FormValidationModule
	// 				)
	// 		},
	// 		// -------------------------------------
	// 		// 8. Charts and Maps
	// 		// -------------------------------------
	// 		// 8.1 Charts
	// 		{
	// 			path: 'misc/charts-maps/charts',
	// 			loadChildren: () => import('./pages/misc/charts-maps/charts/chart.module').then((m) => m.ChartModule)
	// 		},
	// 		// 8.2 Maps
	// 		{
	// 			path: 'misc/charts-maps/maps',
	// 			loadChildren: () => import('./pages/misc/charts-maps/maps/maps.module').then((m) => m.MapsModule)
	// 		},
	// 		// -------------------------------------
	// 		// 9. Colors
	// 		// -------------------------------------
	// 		{
	// 			path: 'misc/colors',
	// 			loadChildren: () => import('./pages/misc/colors/colors.module').then((m) => m.ColorsModule)
	// 		}
	// 	]
	// },
	// {
	// 	path: '',
	// 	component: Layout2Component, canActivate: [AuthGuard],
	// 	children: [
	// 		// 3.7 Login
	// 		{
	// 			path: 'misc/sample-pages/login',
	// 			loadChildren: () => import('./pages/sample-pages/login/login.module').then((m) => m.LoginModule)
	// 		},
	// 		// 3.8 Register
	// 		{
	// 			path: 'misc/sample-pages/register',
	// 			loadChildren: () =>
	// 				import('./pages/sample-pages/register/register.module').then((m) => m.RegisterModule)
	// 		},
	// 		// 3.9 Forgot Password
	// 		{
	// 			path: 'misc/sample-pages/forgot-password',
	// 			loadChildren: () =>
	// 				import('./pages/sample-pages/forgot-password/forgot-password.module').then(
	// 					(m) => m.ForgotPasswordModule
	// 				)
	// 		},
	// 		// 3.11 Error
	// 		{
	// 			path: 'misc/sample-pages/error',
	// 			loadChildren: () => import('./pages/sample-pages/error/error.module').then((m) => m.ErrorModule)
	// 		},
	// 		// 3.12 Coming Soon
	// 		{
	// 			path: 'misc/sample-pages/coming-soon',
	// 			loadChildren: () =>
	// 				import('./pages/sample-pages/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule)
	// 		}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
