import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'app-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
	constructor(public service: AppService) {}

	ngOnInit() {}
}
