import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-dash-financeiro',
  templateUrl: './dash-financeiro.component.html',
  styleUrls: ['./dash-financeiro.component.scss']
})
export class DashFinanceiroComponent implements OnInit {
  @HostBinding('class') class = 'content';

  fromDate: Subject<NgbDate> = new Subject<NgbDate>();
  toDate: Subject<NgbDate> = new Subject<NgbDate>();
  dataSourceTituloPagar: any[] = [];
  dataSourceDocumentoEntrada: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  changeDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
  }

  changeDataTituloPagar(event) {    
    this.dataSourceTituloPagar = event.dataSource;
  }

  changeDataDocumentoEntrada(event) {
    this.dataSourceDocumentoEntrada = event.dataSource;
  }

  exportExcel() {

		const fileName = 'Financeiro.xlsx';
		const wb: XLSX.WorkBook = XLSX.utils.book_new();

		let ws: XLSX.WorkSheet;

    ws = XLSX.utils.json_to_sheet(this.dataSourceTituloPagar);
		XLSX.utils.book_append_sheet(wb, ws, 'Titulos');

		ws = XLSX.utils.json_to_sheet(this.dataSourceDocumentoEntrada);
		XLSX.utils.book_append_sheet(wb, ws, 'Notas');

		XLSX.writeFile(wb, fileName);

  }

}
