import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-estoque',
  templateUrl: './dash-estoque.component.html',
  styleUrls: ['./dash-estoque.component.scss']
})
export class DashEstoqueComponent implements OnInit {

  @HostBinding('class') class = 'content';

  constructor() { }

  ngOnInit(): void {
  }

}
