<div class="card w-os">
	<div class="card-body">
		<div class="d-flex align-items-start">
			<div class="flex-grow-1">
				<h4 class="card-title">{{ widgetTitle }}</h4>
				<!-- <h6 class="card-subtitle mb-5">{{ widgetSubTitle }}</h6> -->
			</div>
			<!-- <div class="ml-auto pl-3 d-none d-sm-inline">
				<a routerLink="./" class="link-bordered">Report</a>
			</div> -->
		</div>

		<nav ngbNav #nav="ngbNav" class="nav-hidden">
			<ng-container ngbNavItem="viewDefault">
				<ng-template ngbNavContent>
					<div class="w-box mt-n2">
						<div *ngFor="let item of osData | slice: 0:4; let i = index" class="w-box__item_25">
							<div class="p-4">
								<div class="d-flex align-items-start mb-3">
									<div class="h6 text-headings font-weight-bold">{{ item.os }}</div>
									<i [ngClass]="['zwicon', 'zwicon-sale-badge']" finish></i>
									<!-- <img
										class="w-20px ml-auto mt-n1 mr-n1"
										[src]="['./assets/img/icons/os/' + item.icon + '.svg']"
										[alt]="[item.os]"
									/> -->
								</div>

								<div class="media align-items-center">

									<div class="media-body">
										<div class="text-muted mb-1">{{ item.title }}</div>
									</div>

								</div>

								<div class="media align-items-center">
									<div class="media-body">

										<div class="h1" [ngClass]="['text-' + item.color]">
											{{ item.value | number }}K
										</div>
									</div>

									<div *ngIf="item.color==='blue'" class="rounded py-2 px-3 h6 text-white"
										[ngClass]="['bg-' + item.color]">
										{{ item.percentage | percent }}
									</div>

								</div>

							</div>
						</div>
					</div>
				</ng-template>
			</ng-container>
		</nav>

		<div [ngbNavOutlet]="nav"></div>

	</div>
</div>