<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">Faturamento por Vendedor</h4>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="table-container">

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="vendedor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendedor </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.item}}
                            {{element.vendedor}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_custo">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Custo </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_custo | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_vendido">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Vendido </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_vendido | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="markup_medio">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> % Markup </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.markup_medio | percent}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="perc_faturamento">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> % Vendas </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.perc_faturamento | percent}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

</div>