import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { AppService } from './app.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Layout1Component } from './components/layouts/layout1/layout1.component';
import { Layout2Component } from './components/layouts/layout2/layout2.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/sidebar/logo/logo.component';
import { SearchComponent } from './components/header/search/search.component';
import { UserComponent } from './components/header/user/user.component';
import { LanguageComponent } from './components/header/language/language.component';
import { TopNavComponent } from './components/header/top-nav/top-nav.component';
import { SettingsComponent } from './components/header/settings/settings.component';
import { NgbDropdownModule, NgbDatepickerModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import {
	PerfectScrollbarModule,
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { HeaderToggleComponent } from './components/header/header-toggle/header-toggle.component';
import { LoginComponent } from './auth/login/login.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { ReportsToolbarComponent } from './reports/faturamento/reports-toolbar/reports-toolbar.component';
import { VendedorComponent } from './reports/faturamento/vendedor/vendedor.component';
import { ProdutoComponent } from './reports/faturamento/produto/produto.component';
import { TipoClienteComponent } from './reports/faturamento/tipo-cliente/tipo-cliente.component';
import { OrigemComponent } from './reports/faturamento/origem/origem.component';
import { AnualComponent } from './reports/faturamento/anual/anual.component';
import { MensalComponent } from './reports/faturamento/mensal/mensal.component';
import { DashboardComponent } from './reports/faturamento/dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { DashEstoqueComponent } from './reports/estoque/dash-estoque/dash-estoque.component';
import { DashEstoqueToolbarComponent } from './reports/estoque/dash-estoque-toolbar/dash-estoque-toolbar.component';
import { EstoqueComponent } from './reports/estoque/estoque/estoque.component';
import { EstoqueLoteComponent } from './reports/estoque/estoque-lote/estoque-lote.component';
import { FabricanteComponent } from './reports/faturamento/fabricante/fabricante.component';
import { ItemNotaFiscalComponent } from './reports/faturamento/item-nota-fiscal/item-nota-fiscal.component';
import { NotaFiscalEntradaComponent } from './reports/estoque/nota-fiscal-entrada/nota-fiscal-entrada.component';
import { DashFinanceiroComponent } from './reports/financeiro/dash-financeiro/dash-financeiro.component';
import { DashFinanceiroToolbarComponent } from './reports/financeiro/dash-financeiro-toolbar/dash-financeiro-toolbar.component';
import { TituloPagarComponent } from './reports/financeiro/titulo-pagar/titulo-pagar.component';
import { DocumentoEntradaComponent } from './reports/financeiro/documento-entrada/documento-entrada.component';

// Custom Scrollbar
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

registerLocaleData(localePt);

@NgModule({
	declarations: [
		AppComponent,
		Layout1Component,
		Layout2Component,
		SidebarComponent,
		HeaderComponent,
		LogoComponent,
		SearchComponent,
		UserComponent,
		LanguageComponent,
		TopNavComponent,
		SettingsComponent,
		HeaderToggleComponent,
		LoginComponent,
		ProfileComponent,
		ReportsToolbarComponent,
		VendedorComponent,
		ProdutoComponent,
		TipoClienteComponent,
		OrigemComponent,
		AnualComponent,
		MensalComponent,
		DashboardComponent,
		DashEstoqueComponent,
		DashEstoqueToolbarComponent,
		EstoqueComponent,
		EstoqueLoteComponent,
		FabricanteComponent,
		ItemNotaFiscalComponent,
		NotaFiscalEntradaComponent,
		DashFinanceiroComponent,
		DashFinanceiroToolbarComponent,
		TituloPagarComponent,
		DocumentoEntradaComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		AppRoutingModule,
		NgbDropdownModule,
		NgbDatepickerModule,
		NgbNavModule,
		NgbProgressbarModule,
		PerfectScrollbarModule,
		NgbAlertModule,
		MatTableModule,
		ChartsModule,
		NgbAccordionModule,
		NgbTooltipModule,
	],
	providers: [
		AppService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
