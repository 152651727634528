import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { ReadParam } from '../../../models/params';
import * as XLSX from 'xlsx';
import { MatTable } from '@angular/material/table';

// export interface PeriodicElement {
//   produto: string;
//   total_custo: number;
//   total_vendido: number;
//   markup_medio: number;
//   perc_faturamento: number
// }

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.scss']
})
export class ProdutoComponent implements OnInit {

  @Output() changeData = new EventEmitter<{dataSource: any[]}>();

  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;

  @ViewChild(MatTable) table!: MatTable<any>;

  readParams: ReadParam = { endpoint: 'produto', user: '', fromDate: '', toDate: '' };

  displayedColumns: string[] = ['produto', 'ean', 'qtde_vendido', 'total_custo', 'total_vendido', 'custo_medio', 'preco_medio', 'markup_medio', 'perc_faturamento'];

  dataSource: any[] = [];

  @ViewChild(MatSort) sort!: MatSort;

  user: any;

  constructor(private crudService: CrudService) { }

  ngOnInit(): void { }

  ngOnChanges() {

    if (this.fromDate && this.toDate && this.fromDate !== null && this.toDate !== null) {

      this.user = localStorage.getItem('auth');
      this.user = JSON.parse(this.user);

      this.readParams.user = this.user.email;
      this.readParams.fromDate = this.fromDate.year + ("00" + this.fromDate.month).slice(-2) + ("00" + this.fromDate.day).slice(-2);
      this.readParams.toDate = this.toDate.year + ("00" + this.toDate.month).slice(-2) + ("00" + this.toDate.day).slice(-2);

      this.crudService.read(this.readParams)
        .subscribe((response) => {

          let totalGeral = { item: '', produto: 'TOTAL GERAL', total_custo: 0, total_vendido: 0, markup_medio: 0, perc_faturamento: 0 }

          response.forEach(function(element, index) {

            element.total_custo = (element.total_custo < 0 ? 0 : element.total_custo);
            element.total_vendido = (element.total_vendido < 0 ? 0 : element.total_vendido);

            if (element.total_custo > 0 && element.total_vendido > 0) {
              element.markup_medio = (element.total_vendido / element.total_custo) - 1;
            } else {
              element.markup_medio = 0;
            }

            totalGeral.total_custo += element.total_custo;
            totalGeral.total_vendido += element.total_vendido;

          });

          if (totalGeral.total_vendido > 0) {
            totalGeral.markup_medio = (totalGeral.total_vendido / totalGeral.total_custo) - 1;
            totalGeral.perc_faturamento = 1;
          }

          let index = 0;
          response.forEach(element => {
            if (element.total_custo > 0 && element.total_vendido > 0) {            
              element.perc_faturamento = element.total_vendido / totalGeral.total_vendido;
            } else {
              element.perc_faturamento = 0;
            }
          });

          response.sort(function (a, b) {
            if (a.total_vendido < b.total_vendido) {
              return 1;
            }
            if (a.total_vendido > b.total_vendido) {
              return -1;
            }
            return 0;
          });          

          let item = 0;
          response.forEach(element => {
            item++;
            element.item = '#' + item.toString() + '. ';
          });

          response.push(totalGeral);

          this.dataSource = response;
          this.table.renderRows();

          this.changeData.emit({dataSource: this.dataSource});          

        }, erro => {
          console.error(erro);
        })

    }

  }

  exportexcel(): void {

		const fileName = 'FaturamentoProduto.xlsx';

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Faturamento Produto');

		/* save to file */
		XLSX.writeFile(wb, fileName);

	}

}
