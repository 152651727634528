import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-layout2',
	templateUrl: './layout2.component.html'
})
export class Layout2Component implements OnInit {
	constructor() {}

	ngOnInit() {}
}
