<div class="settings__header">
	<h2>Beyonder Settings</h2>
	<i class="zwicon zwicon-close settings__close" (click)="service.settingsActive = false"></i>
</div>

<perfect-scrollbar class="scrollbar settings__body">
	<div class="settings__title">Application</div>
	<ul class="settings__inner">
		<li>
			<span>Allow notifications</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-1" checked />
				<label for="settings-toggle-1" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Use LocalStorage</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-2" checked />
				<label for="settings-toggle-2" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Fullscreen browse</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-3" />
				<label for="settings-toggle-3" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Allow analytics</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-4" />
				<label for="settings-toggle-4" class="toggle-switch__helper"></label>
			</div>
		</li>
	</ul>

	<div class="settings__title">User Settings</div>
	<ul class="settings__inner">
		<li>
			<span>Allow registration</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-5" checked />
				<label for="settings-toggle-5" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Enable reporting</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-6" />
				<label for="settings-toggle-6" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Show welcome guidelines</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-7" />
				<label for="settings-toggle-7" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Allow user uploads</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-8" checked />
				<label for="settings-toggle-8" class="toggle-switch__helper"></label>
			</div>
		</li>
	</ul>

	<div class="settings__title">Contents</div>
	<ul class="settings__inner">
		<li>
			<span>Moderate new posts</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-9" checked />
				<label for="settings-toggle-9" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Use rich text formatting</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-10" checked />
				<label for="settings-toggle-10" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Enable embed media</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-11" checked />
				<label for="settings-toggle-11" class="toggle-switch__helper"></label>
			</div>
		</li>
		<li>
			<span>Content sharing</span>
			<div class="toggle-switch ml-auto">
				<input type="checkbox" id="settings-toggle-12" checked />
				<label for="settings-toggle-12" class="toggle-switch__helper"></label>
			</div>
		</li>
	</ul>
</perfect-scrollbar>

<a class="settings__footer" routerLink=""> <i class="zwicon zwicon-cog"></i>Go to settings page </a>
