<div class="language" ngbDropdown>
	<div ngbDropdownToggle class="language__current">
		<img [src]="['./assets/demo/img/languages/' + languages.current + '.png']" alt="English" />
	</div>
	<div ngbDropdownMenu>
		<a ngbDropdownItem routerLink="" class="language__item" *ngFor="let item of languages.list">
			<img [src]="['./assets/img/flags/' + item.flag + '.png']" alt="" />
			{{ item.language }}
		</a>
	</div>
</div>
