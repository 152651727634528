import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Colors, Label } from 'ng2-charts';
import { ReadParam } from 'src/app/models/params';
import { ChartService } from 'src/app/pages/misc/charts-maps/charts/chart.service';
import { CrudService } from 'src/app/services/crud.service';
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-anual',
	templateUrl: './anual.component.html',
	styleUrls: ['./anual.component.scss']
})
export class AnualComponent {

	@Output() changeData = new EventEmitter<{dataSource: any[]}>();

	@Input() fromDate: NgbDate;
	@Input() toDate: NgbDate;

	readParams: ReadParam = { endpoint: '', user: '', fromDate: '', toDate: '' };

	displayedColumns: string[] = ['ano', 'janeiro', 'fevereiro', 'marco', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

	dataSource: any[] = [];

	user: any;

	@ViewChild(MatTable) table!: MatTable<any>;
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild('canvas') canvas: ElementRef;

	widgetTitle = 'Faturamento Anual';
	widgetSubTitle = 'Comparativo dos anos atual e anterior';

	// Chart type
	chartType: ChartType = 'bar';

	// Chart data
	chartData: ChartDataSets[] = [
		{
			label: '',
			data: [],
			barThickness: 20,
			backgroundColor: this.chartService.chartColors.blue.base,
			hoverBackgroundColor: this.chartService.chartColors.blue.hover
		},
		{
			label: '',
			data: [],
			barThickness: 20,
			backgroundColor: this.chartService.chartColors.amber.base,
			hoverBackgroundColor: this.chartService.chartColors.amber.hover
		}
	];

	// Chart Labels
	chartLabels: Label[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

	// Chart options
	chartOptions: ChartOptions = {
		maintainAspectRatio: false,
		responsive: true,
		layout: this.chartService.layout,
		scales: {
			yAxes: [
				{
					ticks: this.chartService.ticksY,
					gridLines: this.chartService.gridLines
				}
			],
			xAxes: [
				{
					offset: true,
					ticks: this.chartService.ticksX,
					gridLines: {
						display: false
					}
				}
			]
		},
		tooltips: this.chartService.tooltips,
		legend: {
			display: false
		}
	};

	constructor(public chartService: ChartService, public crudService: CrudService) { }

	ngOnInit() { }

	ngOnChanges() {

		let data = [
			{ ano: '', m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0, m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0, color: this.chartService.chartColors.blue.base, symbol: 'K' },
			{ ano: '', m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0, m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0, color: this.chartService.chartColors.amber.base, symbol: 'K' }
		];

		this.dataSource[0] = data[0];

		this.user = localStorage.getItem('auth');
		this.user = JSON.parse(this.user);

		this.readParams.user = this.user.email;

		this.readParams.endpoint = 'anoanterior';

		this.crudService.read(this.readParams)
			.subscribe((response) => {

				for (let i = 0; i < response.length; i++) {

					this.chartData[0].label = response[i].ano;

					this.chartData[0].data[parseInt(response[i].mes) - 1] = parseInt((response[i].total_vendido / 1000).toFixed());

					data[0].ano = response[i].ano;

					data[0]['m' + response[i].mes] = parseInt((response[i].total_vendido / 1000).toFixed());
					data[0]['c' + response[i].mes] = parseInt((response[i].total_custo / 1000).toFixed());

				}

				this.dataSource[0] = data[0];
				this.refreshValues();
				this.table.renderRows();

			}, erro => {
				console.error(erro);
			})

		this.readParams.endpoint = 'anoatual';

		this.crudService.read(this.readParams)
			.subscribe((response) => {

				for (let i = 0; i < response.length; i++) {

					this.chartData[1].label = response[i].ano;

					this.chartData[1].data[parseInt(response[i].mes) - 1] = parseInt((response[i].total_vendido / 1000).toFixed());

					data[1].ano = response[i].ano;

					data[1]['m' + response[i].mes] = parseInt((response[i].total_vendido / 1000).toFixed());
					data[1]['c' + response[i].mes] = parseInt((response[i].total_custo / 1000).toFixed());

				}

				this.dataSource[1] = data[1];
				this.refreshValues();
				this.table.renderRows();

			}, erro => {
				console.error(erro);
			})


	}

	refreshValues() {

		let markup1: any = { ano: 'Markup ', m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0, m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0, color: this.chartService.chartColors.blue.base, symbol: '%' };
		let markup2: any = { ano: 'Markup ', m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0, m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0, color: this.chartService.chartColors.amber.base, symbol: '%' };
		let up: any = { ano: 'Crescimento', m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0, m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0, color: this.chartService.chartColors.green.base, symbol: '%' };

		if (this.dataSource[0] && this.dataSource[1]) {

			if (!this.dataSource[2]) {
				markup1.ano += this.dataSource[0].ano;
				this.dataSource.push(markup1);
			}
			if (!this.dataSource[3]) {
				markup2.ano += this.dataSource[1].ano;
				this.dataSource.push(markup2);
			}
			if (!this.dataSource[4]) {
				this.dataSource.push(up);
			}

			this.dataSource[2].m01 = (this.dataSource[0].c01 ? parseInt(((this.dataSource[0].m01 / this.dataSource[0].c01 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m02 = (this.dataSource[0].c02 ? parseInt(((this.dataSource[0].m02 / this.dataSource[0].c02 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m03 = (this.dataSource[0].c03 ? parseInt(((this.dataSource[0].m03 / this.dataSource[0].c03 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m04 = (this.dataSource[0].c04 ? parseInt(((this.dataSource[0].m04 / this.dataSource[0].c04 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m05 = (this.dataSource[0].c05 ? parseInt(((this.dataSource[0].m05 / this.dataSource[0].c05 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m06 = (this.dataSource[0].c06 ? parseInt(((this.dataSource[0].m06 / this.dataSource[0].c06 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m07 = (this.dataSource[0].c07 ? parseInt(((this.dataSource[0].m07 / this.dataSource[0].c07 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m08 = (this.dataSource[0].c08 ? parseInt(((this.dataSource[0].m08 / this.dataSource[0].c08 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m09 = (this.dataSource[0].c09 ? parseInt(((this.dataSource[0].m09 / this.dataSource[0].c09 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m10 = (this.dataSource[0].c10 ? parseInt(((this.dataSource[0].m10 / this.dataSource[0].c10 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m11 = (this.dataSource[0].c11 ? parseInt(((this.dataSource[0].m11 / this.dataSource[0].c11 - 1) * 100).toFixed()) : 0);
			this.dataSource[2].m12 = (this.dataSource[0].c12 ? parseInt(((this.dataSource[0].m12 / this.dataSource[0].c12 - 1) * 100).toFixed()) : 0);

			this.dataSource[3].m01 = (this.dataSource[1].c01 ? parseInt(((this.dataSource[1].m01 / this.dataSource[1].c01 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m02 = (this.dataSource[1].c02 ? parseInt(((this.dataSource[1].m02 / this.dataSource[1].c02 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m03 = (this.dataSource[1].c03 ? parseInt(((this.dataSource[1].m03 / this.dataSource[1].c03 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m04 = (this.dataSource[1].c04 ? parseInt(((this.dataSource[1].m04 / this.dataSource[1].c04 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m05 = (this.dataSource[1].c05 ? parseInt(((this.dataSource[1].m05 / this.dataSource[1].c05 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m06 = (this.dataSource[1].c06 ? parseInt(((this.dataSource[1].m06 / this.dataSource[1].c06 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m07 = (this.dataSource[1].c07 ? parseInt(((this.dataSource[1].m07 / this.dataSource[1].c07 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m08 = (this.dataSource[1].c08 ? parseInt(((this.dataSource[1].m08 / this.dataSource[1].c08 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m09 = (this.dataSource[1].c09 ? parseInt(((this.dataSource[1].m09 / this.dataSource[1].c09 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m10 = (this.dataSource[1].c10 ? parseInt(((this.dataSource[1].m10 / this.dataSource[1].c10 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m11 = (this.dataSource[1].c11 ? parseInt(((this.dataSource[1].m11 / this.dataSource[1].c11 - 1) * 100).toFixed()) : 0);
			this.dataSource[3].m12 = (this.dataSource[1].c12 ? parseInt(((this.dataSource[1].m12 / this.dataSource[1].c12 - 1) * 100).toFixed()) : 0);

			this.dataSource[4].m01 = (this.dataSource[1].m01 ? parseInt(((this.dataSource[1].m01 / this.dataSource[0].m01 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m02 = (this.dataSource[1].m02 ? parseInt(((this.dataSource[1].m02 / this.dataSource[0].m02 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m03 = (this.dataSource[1].m03 ? parseInt(((this.dataSource[1].m03 / this.dataSource[0].m03 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m04 = (this.dataSource[1].m04 ? parseInt(((this.dataSource[1].m04 / this.dataSource[0].m04 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m05 = (this.dataSource[1].m05 ? parseInt(((this.dataSource[1].m05 / this.dataSource[0].m05 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m06 = (this.dataSource[1].m06 ? parseInt(((this.dataSource[1].m06 / this.dataSource[0].m06 - 1) * 100).toFixed()) : 0);

			this.dataSource[4].m07 = (this.dataSource[1].m07 ? parseInt(((this.dataSource[1].m07 / this.dataSource[0].m07 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m08 = (this.dataSource[1].m08 ? parseInt(((this.dataSource[1].m08 / this.dataSource[0].m08 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m09 = (this.dataSource[1].m09 ? parseInt(((this.dataSource[1].m09 / this.dataSource[0].m09 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m10 = (this.dataSource[1].m10 ? parseInt(((this.dataSource[1].m10 / this.dataSource[0].m10 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m11 = (this.dataSource[1].m11 ? parseInt(((this.dataSource[1].m11 / this.dataSource[0].m11 - 1) * 100).toFixed()) : 0);
			this.dataSource[4].m12 = (this.dataSource[1].m12 ? parseInt(((this.dataSource[1].m12 / this.dataSource[0].m12 - 1) * 100).toFixed()) : 0);

			let dados: any[] = [
				{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
				{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
				{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
				{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
				{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
			];
	
			for (let i = 0; i < this.dataSource.length; i++) {
	
				dados[i].Ano = this.dataSource[i].ano;
				dados[i].Janeiro = this.dataSource[i].m01;
				dados[i].Fevereiro = this.dataSource[i].m02;
				dados[i].Março = this.dataSource[i].m03;
				dados[i].Abril = this.dataSource[i].m04;
				dados[i].Maio = this.dataSource[i].m05;
				dados[i].Junho = this.dataSource[i].m06;
				dados[i].Julho = this.dataSource[i].m07;
				dados[i].Agosto = this.dataSource[i].m08;
				dados[i].Setembro = this.dataSource[i].m09;
				dados[i].Outubro = this.dataSource[i].m10;
				dados[i].Novembro = this.dataSource[i].m11;
				dados[i].Dezembro = this.dataSource[i].m12;
	
			}
		
			this.changeData.emit({dataSource: dados});

		}

	}

	exportexcel(): void {

		let dados: any[] = [
			{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
			{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
			{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
			{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
			{ Ano: '', Janeiro: 0, Fevereiro: 0, Março: 0, Abril: 0, Maio: 0, Junho: 0, Julho: 0, Agosto: 0, Setembro: 0, Outubro: 0, Novembro: 0, Dezembro: 0 },
		];

		for (let i = 0; i < this.dataSource.length; i++) {

			dados[i].Ano = this.dataSource[i].ano;
			dados[i].Janeiro = this.dataSource[i].m01;
			dados[i].Fevereiro = this.dataSource[i].m02;
			dados[i].Março = this.dataSource[i].m03;
			dados[i].Abril = this.dataSource[i].m04;
			dados[i].Maio = this.dataSource[i].m05;
			dados[i].Junho = this.dataSource[i].m06;
			dados[i].Julho = this.dataSource[i].m07;
			dados[i].Agosto = this.dataSource[i].m08;
			dados[i].Setembro = this.dataSource[i].m09;
			dados[i].Outubro = this.dataSource[i].m10;
			dados[i].Novembro = this.dataSource[i].m11;
			dados[i].Dezembro = this.dataSource[i].m12;

		}

		/* table id is passed over here */
		// let element = document.getElementById('excel-table');
		// const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
		const fileName = 'FaturamentoAnual.xlsx';

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dados);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Faturamento Anual');

		/* save to file */
		XLSX.writeFile(wb, fileName);

	}

}
