<app-dash-financeiro-toolbar (changeDate)="changeDate($event)" (exportExcel)="exportExcel()"></app-dash-financeiro-toolbar>

<div class="content__inner">

    <app-titulo-pagar [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataTituloPagar($event)"></app-titulo-pagar>

    <app-documento-entrada [fromDate]="fromDate" [toDate]="toDate" (changeData)="changeDataDocumentoEntrada($event)"></app-documento-entrada>

</div>

