<div class="toolbar">
	<h2 class="toolbar__title">Dashboard</h2>
	<ol class="breadcrumb ml-4 d-none d-sm-flex">
		<li class="breadcrumb-item">Relatórios</li>
		<li class="breadcrumb-item">Financeiro</li>
	</ol>

	<i class="ml-auto"></i>

	<div class="date-picker d-none d-md-inline" style="z-index: 2000;">
		<input
			hidden
			name="datepicker"
			ngbDatepicker
			#datepicker="ngbDatepicker"
			[autoClose]="'outside'"
			(dateSelect)="onDateSelection($event)"
			[displayMonths]="2"
			[dayTemplate]="t"
			outsideDays="hidden"
			[startDate]="fromDate!"
			[placement]="'bottom left'"
		/>

		<ng-template #t let-date let-focused="focused">
			<span
				class="range"
				[class.focused]="focused"
				[class.active]="isRange(date)"
				[class.faded]="isHovered(date) || isInside(date)"
				(mouseenter)="hoveredDate = date"
				(mouseleave)="hoveredDate = null"
			>
				{{ date.day }}
			</span>	
		</ng-template>

	</div>

	<div class="nav nav--hover nav--icon nav--compact mr-n2 align-items-center">
		<div class="nav-link d-none d-md-flex align-items-center" (click)="datepicker.toggle()">
			<i class="zwicon zwicon-calendar-week mr-2"></i>
			{{ formatter.format(fromDate) }}—{{ formatter.format(toDate) }}
		</div>

		<i class="toolbar__divider mx-2 d-none d-md-inline"></i>

		<a class="nav-link" (click)="clickRefresh()">
			<i class="zwicon zwicon-refresh-double"></i><span>Atualizar</span>
		</a>

		<i class="toolbar__divider mx-2 d-none d-md-inline"></i>

		<!-- <a routerLink="./" class="nav-link"><i class="zwicon zwicon-plus-circle"></i></a> -->
		<!-- <a routerLink="./" class="nav-link"><i class="zwicon zwicon-slider-circle-h"></i></a> -->
		<!-- <a routerLink="./" class="nav-link"><i class="zwicon zwicon-line-chart"></i></a> -->
		<!-- <a routerLink="./" class="nav-link"><i class="zwicon zwicon-floppy"></i></a> -->
		<a class="nav-link" (click)="clickExportExcel()">
			<i class="zwicon zwicon-download"></i><span>Download</span>
		</a>
	</div>
</div>
