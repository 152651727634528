import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';

@Component({
	selector: 'app-header-toggle',
	templateUrl: './header-toggle.component.html',
	styleUrls: ['./header-toggle.component.scss']
})
export class HeaderToggleComponent implements OnInit {
	constructor(public service: AppService) {}

	ngOnInit() {}
}
