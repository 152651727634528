<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">Estoque de Produtos</h4>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="table-container mat-elevation-z8">

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="codigo">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.codigo}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="descricao">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Descrição </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.descricao}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="unidade_medida">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Un.Med </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.unidade_medida}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="custo_stander">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> C.Stander </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.custo_stander |
                        currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="preco_venda_1">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Preço 01 </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.preco_venda_1 |
                        currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="preco_es">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Preço ES </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.preco_es | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="markup">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Markup </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.markup/100 | percent}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="markup_promo">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Mark.Promo </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.markup_promo/100 |
                        percent}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ean">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> EAN </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.ean}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="fabricante">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Fabricante </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.fabricante}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="caixa_embalagem">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Cx.Embal </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.caixa_embalagem |
                        number}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="preco_50">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Preço 50% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.preco_50 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="registro_ms">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Registro MS </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.registro_ms}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="principio_ativo">
                    <th class='format-string' mat-header-cell *matHeaderCellDef mat-sort-header> Principio Ativo
                    </th>
                    <td class='format-string' mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.principio_ativo}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="saldo_estoque">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Estoque </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.saldo_estoque | number}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pf_12">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PF 12% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pf_12 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pmc_12">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PMC 12% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pmc_12 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pf_17">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PF 17% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pf_17 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pmc_17">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PMC 17% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pmc_17 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pf_18">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PF 18% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pf_18 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pmc_18">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PMC 18% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pmc_18 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pf_20">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PF 20% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pmc_20 | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="pmc_20">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> PMC 20% </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.pmc_20 | currency}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

</div>