<div class="card">

    <div class="card-body">

        <div class="d-flex align-items-start">
            <div class="flex-grow-1">
                <h4 class="card-title">Faturamento por Produto</h4>
            </div>
            <nav class="nav nav--icon nav--hover nav--compact mr-n2 mt-n2 d-none d-sm-flex">
                <a class="nav-link" (click)="exportexcel()">
                    <i class="zwicon zwicon-download"></i><span>Download</span>
                </a>
            </nav>
        </div>

        <div class="table-container">

            <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="produto">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Produto </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="flex-grow-1 text-headings d-flex align-items-center">
                            {{element.item}}
                            {{element.produto}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ean">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> EAN </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text-left">
                            {{element.ean}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="qtde_vendido">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Quantidade </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.qtde_vendido | number}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_custo">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Custo </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_custo | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_vendido">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> TT Vendido </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.total_vendido | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="custo_medio">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Custo M </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{((element.total_custo ?
                        element.total_custo : 0) /
                        (element.qtde_vendido ? element.qtde_vendido : 1)).toFixed(2) | currency}} </td>
                </ng-container>

                <ng-container matColumnDef="preco_medio">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> Preço M </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{((element.total_vendido ?
                        element.total_vendido : 0) /
                        (element.qtde_vendido ? element.qtde_vendido : 1)).toFixed(2) | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="markup_medio">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> % Mark </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.markup_medio | percent}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="perc_faturamento">
                    <th class='format-number' mat-header-cell *matHeaderCellDef mat-sort-header> % Vend </th>
                    <td class='format-number' mat-cell *matCellDef="let element"> {{element.perc_faturamento | percent}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

</div>