import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: string = '';
  pass: string = '';
  origin: string = 'Externo';

  action: string = 'Acessar';
  loading: boolean = false;
  alert: boolean = false;
  message: string = 'Ocorreu um erro!';

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  login() {

    this.action = 'Aguarde...'
    this.loading = true;
    this.alert = false;

    this.authService.auth(this.user, this.pass)
      .subscribe((response) => {

        let objeto = response.data;

        objeto.Authorization = btoa(this.user + ":" + this.pass);
        objeto.Origin = this.origin;
        
        localStorage.setItem('auth', JSON.stringify(objeto));

        this.action = 'Acessar';
        this.loading = false;

        this.router.navigate(['/reports/faturamento']);

      }, erro => {

        console.error(erro);

        this.action = 'Tentar Novamente';

        this.message = erro.error.errorMessage;
        this.loading = false;
        this.alert = true;

      })

  }

  close() {
		this.alert = !this.alert;
	}

}
