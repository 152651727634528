import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { ReadParam } from '../../../models/params';
import * as XLSX from 'xlsx';
import { MatTable } from '@angular/material/table';

export interface PeriodicElement {
  color: string;
  origem: string;
  total_custo: number;
  total_vendido: number;
  markup_medio: number;
  perc_faturamento: number
}

@Component({
  selector: 'app-origem',
  templateUrl: './origem.component.html',
  styleUrls: ['./origem.component.scss']
})
export class OrigemComponent implements OnInit {

  @Output() changeData = new EventEmitter<{dataSource: any[]}>();

  @Input() fromDate: NgbDate;
  @Input() toDate: NgbDate;

  @ViewChild(MatTable) table!: MatTable<any>;

  readParams: ReadParam = { endpoint: 'origem', user: '', fromDate: '', toDate: '' };

  displayedColumns: string[] = ['origem', 'total_custo', 'total_vendido', 'markup_medio', 'perc_faturamento'];

  dataSource: any[] = [];
  // dataSource: Observable<any[]> = new Observable();

  // Chart type
  chartType: ChartType = 'doughnut';

  // Chart data
  chartData: ChartDataSets[] = [
    {
      data: [],
      backgroundColor: ['#E26CF5', '#26C6DA', '#D4E157', '#FFCA28', '#fd7171'],
      hoverBackgroundColor: ['#CB58DE', '#1BADC0', '#C0CD45', '#E4B31C', '#cf5151'],
      hoverBorderWidth: 0
    }
  ];

  total: number = 0;

  // Chart Label
  chartLabel: Label[] = [];

  // Chart options
  chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    cutoutPercentage: 65,
    tooltips: {
      titleFontFamily: 'Roboto',
      titleFontStyle: 'normal',
      titleFontColor: '#deeeff',
      bodyFontFamily: 'Roboto',
      bodyFontSize: 11,
      bodyFontColor: '#deeeff',
      xPadding: 15,
      yPadding: 15,
      cornerRadius: 3,
      multiKeyBackground: 'transparent',
      backgroundColor: '#192f40',
      displayColors: false
    },
    legend: {
      display: false
    }
  };

  @ViewChild(MatSort) sort!: MatSort;

  user: any;

  constructor(private crudService: CrudService) { }

  ngOnInit(): void { }

  ngOnChanges() {

    if (this.fromDate && this.toDate && this.fromDate !== null && this.toDate !== null) {

      this.user = localStorage.getItem('auth');
      this.user = JSON.parse(this.user);

      this.readParams.user = this.user.email;
      this.readParams.fromDate = this.fromDate.year + ("00" + this.fromDate.month).slice(-2) + ("00" + this.fromDate.day).slice(-2);
      this.readParams.toDate = this.toDate.year + ("00" + this.toDate.month).slice(-2) + ("00" + this.toDate.day).slice(-2);

      this.crudService.read(this.readParams)
        .subscribe((response) => {

          let colors = this.chartData[0].backgroundColor;
          let totalGeral = { color: 'black', origem: 'TOTAL GERAL', total_custo: 0, total_vendido: 0, markup_medio: 0, perc_faturamento: 0 }

          response.forEach(function(element, index) {

            element.color = colors[index];
            element.total_custo = (element.total_custo < 0 ? 0 : element.total_custo);
            element.total_vendido = (element.total_vendido < 0 ? 0 : element.total_vendido);

            if (element.total_custo > 0 && element.total_vendido > 0) {
              element.markup_medio = (element.total_vendido / element.total_custo) - 1;
            } else {
              element.markup_medio = 0;
            }

            totalGeral.total_custo += element.total_custo;
            totalGeral.total_vendido += element.total_vendido;

          });

          if (totalGeral.total_vendido > 0) {
            this.total = parseInt((totalGeral.total_vendido / 1000).toFixed());
            totalGeral.markup_medio = (totalGeral.total_vendido / totalGeral.total_custo) - 1;
            totalGeral.perc_faturamento = 1;
          }

          let index = 0;
          response.forEach(element => {
            if (element.total_custo > 0 && element.total_vendido > 0) {            
              element.perc_faturamento = element.total_vendido / totalGeral.total_vendido;
            } else {
              element.perc_faturamento = 0;
            }
            this.chartData[0].data[index] = parseInt((element.perc_faturamento * 100).toFixed());
            index++;
            this.chartLabel.push(element.origem);
          });

          response.sort(function (a, b) {
            if (a.perc_faturamento < b.perc_faturamento) {
              return 1;
            }
            if (a.perc_faturamento > b.perc_faturamento) {
              return -1;
            }
            return 0;
          });          

          response.push(totalGeral);

          this.dataSource = response;
          this.table.renderRows();

          this.changeData.emit({dataSource: this.dataSource});

        }, erro => {
          console.error(erro);
        })

    }

  }

	exportexcel(): void {

		const fileName = 'FaturamentoOrigem.xlsx';

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Faturamento Origem');

		/* save to file */
		XLSX.writeFile(wb, fileName);

	}

}

