import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dash-financeiro-toolbar',
  templateUrl: './dash-financeiro-toolbar.component.html',
  styleUrls: ['./dash-financeiro-toolbar.component.scss']
})
export class DashFinanceiroToolbarComponent implements OnInit {
	@Output() changeDate = new EventEmitter<{fromDate: NgbDate, toDate: NgbDate}>();
	@Output() exportExcel = new EventEmitter;

	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null;
	toDate: NgbDate | null;

	constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {		
		this.fromDate = calendar.getNext(calendar.getToday(), 'd', -30);
		this.toDate = calendar.getNext(calendar.getToday(), 'd', 1000);
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
		this.changeDate.emit({fromDate: this.fromDate, toDate: this.toDate});
	}

	clickRefresh() {
		this.changeDate.emit({fromDate: this.calendar.getToday(), toDate: this.calendar.getToday()});
		this.changeDate.emit({fromDate: this.fromDate, toDate: this.toDate});
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate &&
			!this.toDate &&
			this.hoveredDate &&
			date.after(this.fromDate) &&
			date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	ngOnInit(): void {
		this.changeDate.emit({fromDate: this.fromDate, toDate: this.toDate});
	}

	clickExportExcel(): void {
		this.exportExcel.emit();
	}

}
