import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { ReadParam } from '../models/params';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  user: any;
  httpOptions: any;
  host: string = 'https://sfa.maispedido.com.br/api/ext/';
  // hostInterno: string = 'http://192.168.19.5:8280/';
  // hostExterno: string = 'http://diskmedpadua.no-ip.org:8280/';

  constructor(private httpClient: HttpClient, private location: Location, private router: Router) {

    this.user = localStorage.getItem('auth');
    this.user = JSON.parse(this.user);

    // if (this.user.Origin === 'Interno') { 
    //   this.host = this.hostInterno;
    // } else {
    //   this.host = this.hostExterno;
    // }

    // console.log(this.user.Origin + ' => ' + this.host);

    this.httpOptions = {
      headers: new HttpHeaders({
        // Authorization: 'BASIC ' + this.user.Authorization
      })
    };

  }

  read(params: ReadParam): Observable<any> {

    console.log(params)

    let url = this.host + params.endpoint + '?user=' + params.user.toUpperCase();

    url += (params.fromDate ? '&datestart=' + params.fromDate : '') + (params.toDate ? '&dateend=' + params.toDate : '');

    return this.httpClient.get<any>(url); //, this.httpOptions);

  }

}
