import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dash-estoque-toolbar',
  templateUrl: './dash-estoque-toolbar.component.html',
  styleUrls: ['./dash-estoque-toolbar.component.scss']
})
export class DashEstoqueToolbarComponent implements OnInit {

	@Output() exportExcel = new EventEmitter;

	constructor() {		
	}

	ngOnInit(): void {
	}

	clickExportExcel(): void {
		this.exportExcel.emit();
	}

}
