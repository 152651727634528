<div class="top-nav">
	<!-- Mobile search toggle icon -->
	<div class="top-nav__item d-md-none">
		<i class="zwicon zwicon-search" (click)="service.searchToggled = true"></i>
	</div>
	<!-- End mobile search toggle icon -->

	<!-- Notifications -->
	<!-- <div class="top-nav__item top-nav__notify" ngbDropdown>
		<i class="zwicon zwicon-bell" ngbDropdownToggle></i>

		<div ngbDropdownMenu class="dropdown-menu-lg">
			<div class="dropdown-header bg-gradient-plum">
				Notifications

				<nav ngbNav #notifications="ngbNav" class="nav nav-fill dropdown__nav" [destroyOnHide]="true">
					<ng-container ngbNavItem>
						<a ngbNavLink>Sales<i></i></a>
						<ng-template ngbNavContent>
							<perfect-scrollbar class="scrollbar" style="height: 300px">
								<a *ngFor="let list of orderNotifications" routerLink="" class="media media--hover">
									<div class="avatar mr-3">
										<span class="avatar__char" [ngClass]="['bg-' + list.color]"
											><i class="zwicon" [ngClass]="['zwicon-' + list.icon]"></i
										></span>
									</div>
									<div class="media-body">
										<div
											class="h6 text-headings mb-1"
											[class.font-weight-bold]="list.status == 'unread'"
										>
											{{ list.id + ' ' + list.title }}
										</div>
										<div class="small text-muted">{{ list.time }}</div>
									</div>
								</a>
							</perfect-scrollbar>
							<div class="dropdown__footer">
								<a routerLink=""><i class="zwicon zwicon-minus-circle"></i>Clear notifications</a>
							</div>
						</ng-template>
					</ng-container>
					<ng-container ngbNavItem>
						<a ngbNavLink>Support<i></i></a>
						<ng-template ngbNavContent>
							<perfect-scrollbar class="scrollbar" style="height: 300px">
								<a *ngFor="let list of supportNotifications" routerLink="" class="media media--hover">
									<div class="avatar mr-3">
										<span class="avatar__char" [ngClass]="['bg-' + list.color]"
											><i class="zwicon" [ngClass]="['zwicon-' + list.icon]"></i
										></span>
									</div>
									<div class="media-body">
										<div
											class="h6 mb-1 text-headings"
											[class.font-weight-bold]="list.status == 'unread'"
										>
											{{ list.id + ' ' + list.title }}
										</div>
										<div class="small text-muted">{{ list.time }}</div>
									</div>
								</a>
							</perfect-scrollbar>
							<div class="dropdown__footer">
								<a routerLink=""><i class="zwicon zwicon-minus-circle"></i>Clear notifications</a>
							</div>
						</ng-template>
					</ng-container>
					<ng-container ngbNavItem>
						<a ngbNavLink>Others</a>
						<ng-template ngbNavContent>
							<div class="py-5 text-center">
								<div class="img-message mt-4">
									<i class="zwicon zwicon-bell-slash"></i>
								</div>
								<div class="pt-3">All caught up!</div>
							</div>
						</ng-template>
					</ng-container>
				</nav>
			</div>

			<div [ngbNavOutlet]="notifications"></div>
		</div>
	</div> -->
	<!-- End notifications -->

	<!-- Mails -->
	<!-- <div class="top-nav__item" ngbDropdown>
		<i class="zwicon zwicon-mail" ngbDropdownToggle></i>
		<div ngbDropdownMenu class="dropdown-menu-lg">
			<div class="dropdown-header bg-gradient-green">
				Mails

				<nav ngbNav #mail="ngbNav" class="nav nav-fill dropdown__nav">
					<ng-container ngbNavItem>
						<a ngbNavLink>Everything</a>
						<ng-template ngbNavContent>
							<perfect-scrollbar class="scrollbar" style="height: 300px">
								<a *ngFor="let mail of mails" routerLink="" class="media media--hover">
									<div class="avatar mr-3">
										<span *ngIf="mail.cap" class="avatar__char" [ngClass]="['bg-' + mail.color]">{{
											mail.cap
										}}</span>
										<img
											*ngIf="mail.image"
											class="avatar__img"
											[src]="['./assets/demo/img/contacts/' + mail.image]"
											alt=""
										/>
									</div>
									<div class="media-body overflow-hidden">
										<div
											class="h6 text-headings mb-1"
											[class.font-weight-bold]="mail.status == 'unread'"
										>
											{{ mail.user }}
											<small class="text-muted ml-2">{{ mail.time }}</small>
										</div>
										<div class="small text-muted text-truncate">{{ mail.content }}</div>
									</div>
								</a>
							</perfect-scrollbar>
							<div class="dropdown__footer">
								<a routerLink=""><i class="zwicon zwicon-arrow-circle-right"></i>View more mails</a>
							</div>
						</ng-template>
					</ng-container>
					<ng-container ngbNavItem>
						<a ngbNavLink>Unread</a>
						<ng-template ngbNavContent>
							<perfect-scrollbar class="scrollbar" style="height: 300px">
								<ng-container *ngFor="let mail of mails">
									<a *ngIf="mail.status == 'unread'" routerLink="" class="media media--hover">
										<div class="avatar mr-3">
											<span
												*ngIf="mail.cap"
												class="avatar__char"
												[ngClass]="['bg-' + mail.color]"
												>{{ mail.cap }}</span
											>
											<img
												*ngIf="mail.image"
												class="avatar__img"
												[src]="['./assets/demo/img/contacts/' + mail.image]"
												alt=""
											/>
										</div>
										<div class="media-body overflow-hidden">
											<div
												class="h6 text-headings mb-1"
												[class.font-weight-bold]="mail.status == 'unread'"
											>
												{{ mail.user }}
												<small class="text-muted ml-2">{{ mail.time }}</small>
											</div>
											<div class="text-muted small text-truncate">{{ mail.content }}</div>
										</div>
									</a>
								</ng-container>
							</perfect-scrollbar>
							<div class="dropdown__footer">
								<a routerLink=""><i class="zwicon zwicon-checkmark-circle"></i>Mark all as read</a>
							</div>
						</ng-template>
					</ng-container>
				</nav>
			</div>

			<div [ngbNavOutlet]="mail" class="mt-2"></div>
		</div>
	</div> -->
	<!-- End mails -->

	<!-- Tasks -->
	<!-- <div class="top-nav__item d-none d-sm-block" ngbDropdown>
		<i class="zwicon zwicon-task" ngbDropdownToggle></i>
		<div ngbDropdownMenu class="dropdown-menu-lg">
			<div class="dropdown-header bg-gradient-cyan">Ongoing Tasks</div>
			<a routerLink="" *ngFor="let task of tasks" class="media media--hover">
				<div class="media-body">
					<div class="text-headings h6 font-weight-bold mb-2">{{ task.name }}</div>
					<ngb-progressbar [type]="task.color" [value]="task.completed"></ngb-progressbar>
				</div>
			</a>
		</div>
	</div> -->
	<!-- End tasks -->

	<!-- Settings toggle icon -->
	<!-- <div
		class="top-nav__item d-none d-sm-block"
		(click)="service.settingsActive = true; service.asideMobileActive = false; service.sidebarActive = false"
	>
		<i class="zwicon zwicon-cog"></i>
	</div> -->
	<!-- End settings toggle icon -->
</div>
